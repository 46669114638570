import React from 'react'
import { Layout, Stack, Main, Hero, Sidebar } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Heading, Text, Badge, Box } from 'theme-ui'


export default props => {
    const data = useStaticQuery(technoart2021Querry)
  let partners2021 = []
  for (const edge of data.year2021.edges) {
    partners2021.push(getImage(edge.node))
  }
  return (
    <Layout {...props}>
      <Seo title='Contact' />
      <Hero sx={{ bg: `#b40c01` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 64,
            paddingBottom: 64,
            aligntItems: 'center'
          }}
        >
          <Heading
            style={{ color: '#ffffff', justifyContent: 'center' }}
            variant='h1'
          >
            Досліджуй космос заради Землі
          </Heading>
        </div>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Heading variant='h1'>ТехноАртХакатон 2021</Heading>
          <Heading variant='h2'>Прориваємось у космос крізь онлайн</Heading>
          <Text>
            Унікальний хакатон для школярів! Уперше студенти провідного вишу
            столиці супроводжуватимуть школярів під час створення справжніх
            космічних проєктів за підтримки менторів КПІ ім. Ігоря Сікорського
            та Noosphere.
            <br />
            <br />
            Дізнайся про основні етапи заходу, знайомся з журі, спікерами,
            експертами та менторами команд, вивчай інструкції для учасників.
            <br />
            <br />
            Участь за попередньою реєстрацією, тож поспішай заповнити форму та
            приєднуйся до нашого чату в Telegram!
          </Text>
          <Divider />
          <Heading variant='h2'>ЗА ПІДТРИМКИ</Heading>
          <Heading variant='h4'>ТИХ, ЩО РОБЛЯТЬ ЦЕЙ ФЕСТИВАЛЬ ПОЗИТИВНИМ!</Heading>
          <Box
            sx={{
              overflow: `hidden`,
              position: `relative`,
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {partners2021.map(partner => (
              <Img
                style={{
                  borderRadius: '1rem',
                  marginRight: 16,
                  marginBottom: 32
                }}
                image={partner}
              />
            ))}
          </Box>
        </Main>
      </Stack>
    </Layout>
  )
}

const technoart2021Querry = graphql`
query technoart2021Querry {
  year2021: allFile(filter: {absolutePath: {regex: "./partners/2021/(.*).(jpeg|jpg|gif|png)/"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
          height: 128)
        }
      }
    }
  }
}
`